import React, { useEffect } from 'react'

// assets imports & Styling
import { MainWrapper, MainHero, HeroContent, Newsletter, SideHero} from '../../styles/home.style'

import {colors} from "../../styles/theme";

import { Button, Flex } from '@chakra-ui/react';
import {FaUserCircle} from "react-icons/fa";
import { MdArrowForward } from 'react-icons/md';
// import Navigation from './navigation';
import background from "../../assets/background.svg";
import MobileStoreButton from 'react-mobile-store-button';

const Homepage = () => {
    
   
    
    const iOSUrl = '/suta.apk';
  return (
    
    <>
        {/* <Navigation /> */}
        <MainWrapper>
            {/*   =========== hero section ==========     */}
            <MainHero>
                <HeroContent>
                    <h2>Simplifying Your <span style={{ color: `${colors.primaryColor}`}}>Bill Payments</span></h2>
                    <p>On Suta, we simplify how you pay bills into a single, intuitive platform that saves you time, effort, and stress.</p>
                    <MobileStoreButton
                  store="android"
                  url={iOSUrl}
                  linkProps={{ title: 'iOS Store Button' }}
                />
                    
                </HeroContent>

                {/*    ============ newsletter form ========= */}
                <Newsletter>
                    <h3>Register/Login Your Account</h3>

                    {/* ----- form input ---- */}
                    <div className={'input-container'}>
                        <Flex
                            justifyContent={'start'}
                            alignItems={'center'}
                            gap={'5px'}
                        >
                            <FaUserCircle />
                            <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)} type={'text'} placeholder={'081++++++++++'}/>
                        </Flex>

                        <a href='/auth/register'>
                            <span className='mobile-text'>
                                Get started
                            </span>
                            <span className="mobile-icon">
                                <MdArrowForward style={{ color: "#fff", fontSize: "24px" }} />
                            </span>
                        </a>
                        {/* <button>
                        </button> */}
                    </div>
                </Newsletter>
            </MainHero>

            {/*  ============== side hero section =========== */}
            <SideHero>
                <img src={background} alt='background' />
            </SideHero>
        </MainWrapper>
    </>
  )
}


export default Homepage;